//import necessary files for PricingComparison component
import React from "react";
import "./PricingComparison.scss";
import images from "../../constants";
//import react icon hi library for green checkmark
import { HiCheck, HiX } from "react-icons/hi";
//import calculateVoipPlanPrice function from utils folder
const PricingComparisonTolaTenderPro = () => {
  return (
    //Pricing comparison component
    <div className="container py-3">
      <main>
        <h2 className="display-3 text-center mb-4">
          Why Choose <strong>TolaTenderPro&trade;</strong> Over Others?
        </h2>
        <div className="table-responsive">
          <table className="table text-center">
            <thead>
              <tr>
                <th className="w-34"></th>
                <th className="w-22">TolaTenderPro</th>
                <th className="w-22">Tender Alerting Service</th>
                <th className="w-22">Tender Training</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row" className="text-start">
                  Pricing
                </th>
                <td>From R 450.00 per search and bid for you</td>
                <td>From R100 to R1500 per month</td>
                <td>From R1000 to R5000 per student</td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Tender Search
                </th>
                <td>
                  <span className="plan-features-available">
                    <HiCheck />
                  </span>
                </td>
                <td>
                  <span className="plan-features-available">
                    <HiCheck />
                  </span>
                </td>
                <td>
                  <span className="plan-features-unavailable">
                    <HiX />
                  </span>
                </td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Bid Proposal Preparation
                </th>
                <td>
                  <span className="plan-features-available">
                    <HiCheck />
                  </span>
                </td>
                <td>
                  <span className="plan-features-unavailable">
                    <HiX />
                  </span>
                </td>
                <td>
                  <span className="plan-features-unavailable">
                    <HiX />
                  </span>
                </td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Industry Expert Advise
                </th>
                <td>
                  <span className="plan-features-available">
                    <HiCheck />
                  </span>
                </td>
                <td>
                  <span className="plan-features-unavailable">
                    <HiX />
                  </span>
                </td>
                <td>
                  <span className="plan-features-unavailable">
                    <HiX />
                  </span>
                </td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Pricing Guidance
                </th>
                <td>
                  <span className="plan-features-available">
                    <HiCheck />
                  </span>
                </td>
                <td>
                  <span className="plan-features-unavailable">
                    <HiX />
                  </span>
                </td>
                <td>
                  <span className="plan-features-available">
                    <HiCheck />
                  </span>
                </td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Pricing Research Tools + Access Our 15 000 Suppliers
                </th>
                <td>
                  <span className="plan-features-available">
                    <HiCheck />
                  </span>
                </td>
                <td>
                  <span className="plan-features-unavailable">
                    <HiX />
                  </span>
                </td>
                <td>
                  <span className="plan-features-unavailable">
                    <HiX />
                  </span>
                </td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Bid Submission
                </th>
                <td>
                  <span className="plan-features-available">
                    <HiCheck />
                  </span>
                </td>
                <td>
                  <span className="plan-features-unavailable">
                    <HiX />
                  </span>
                </td>
                <td>
                  <span className="plan-features-unavailable">
                    <HiX />
                  </span>
                </td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Gain Access To Faster-To-Get Subcontracting Opportunities
                </th>
                <td>
                  <span className="plan-features-available">
                    <HiCheck />
                  </span>
                </td>
                <td>
                  <span className="plan-features-unavailable">
                    <HiX />
                  </span>
                </td>
                <td>
                  <span className="plan-features-unavailable">
                    <HiX />
                  </span>
                </td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Get Funding
                </th>
                <td>
                  <span className="plan-features-available">
                    <HiCheck />
                  </span>
                </td>
                <td>
                  <span className="plan-features-unavailable">
                    <HiX />
                  </span>
                </td>
                <td>
                  <span className="plan-features-unavailable">
                    <HiX />
                  </span>
                </td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Outcome
                </th>
                <td>
                  More effective RFQs and tender bids, fewer mistakes, and a
                  higher likelihood of success
                </td>
                <td>
                  Increased risk of errors due to lack of support beyond alerts
                </td>
                <td>
                  Improved knowledge but no guarantee of correctly executed bids
                  because of generalized no indutry specific training.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <ul>
            <li>
              <strong>
                Unlike Tender Alerting Services, which only provide information
                on tender opportunities,
              </strong>
              &nbsp;we recognized a gap when we ran our own
              TolaTender.co.za&nbsp;alerting service a few years back—many
              entrepreneurs struggled with navigating computers to effectively
              search for RFQs and tenders. TolaTenderPro addresses this by
              offering a comprehensive end-to-end service, managing everything
              from the initial search to the final submission, to ensure a
              seamless experience.
            </li>
            <li>
              <strong>Tender Training offers basic lessons</strong>, but our
              experience with conducting a tender masterclass showed that
              entrepreneurs often still make mistakes in bidding, especially
              regarding pricing, leading to lost time and missed chances.
              TolaTenderPro, however, provides a thorough end-to-end service,
              including detailed guidance on pricing and access to our pricing
              research tool to ensure your bids are competitive. This advantage
              allows you to submit more effective RFQs and tender bids. We take
              care of everything from the initial search to the final
              submission, guaranteeing a smooth and efficient process.
            </li>
            <li>
              <strong>
                TolaTenderPro's&nbsp; team consists of industry experts like
                Quantity Surveyors
              </strong>
              , who understand the nuances of tender bidding, particularly in
              the construction sector. In contrast, Tender Training may offer a
              more generalized approach, which might not cater to the specific
              needs of your business.
            </li>
          </ul>
        </div>
      </main>
    </div>
  );
};

export default PricingComparisonTolaTenderPro;
