import React from "react";
//import the css file for styling
import "./TenderBriefAttend.scss";

//import assets from constants
import assets from "../../constants";

//import cookies hook from react cookies library
import { useCookies } from "react-cookie";

// Create a style object and set the `backgroundImage` property to the URL of the background image variable.
const styles = {
  backgroundImage: `url(${assets.bgpofinance1})`,
  opacity: 1,
  backgroundPosition: `top left`,
  bacgroundSize: "cover",
};
//create TolaTenderProle
const TolaTenderPro = (props) => {
  const [cookies] = useCookies(["affid", "urlParams"]);
  //let affilliateID = cookies.affid;
  // if (cookies.affid != null) {
  //   affilliateID = cookies.affid;
  //   //log in a console the value of the cookie
  //   //console.log("Cookie set to:" + affilliateID);
  // } else {
  //   //log in a console that the cookie is null
  //   //console.log("cookie is null");
  // }

  const $utm_source = cookies.urlParams.utm_source || "";
  const $utm_medium = cookies.urlParams.utm_medium || "";
  const $utm_campaign = cookies.urlParams.utm_campaign || "";
  const $utm_term = cookies.urlParams.utm_term || "";
  const $utm_content = cookies.urlParams.utm_content || "";
  const affid = cookies.affid || "";

  //Handle when user click Buy Button and redirect the user to a new url
  const handleRFQButton = (event) => {
    event.preventDefault();

    //console.log("AFFILIATE ID: " + affid);

    //redirect to url but open a blank page

    const rfqUrl = "https://marketdirect.zohobookings.com/#/tenderpro?";
    window.open(rfqUrl, "_blank");
  };

  //Handle when user click Buy Button and redirect the user to a new url
  const handleLearnMore = (event) => {
    event.preventDefault();

    //console.log("AFFILIATE ID: " + affid);

    //redirect to url but open a blank page
    //const rfqUrl ="https://forms.zoho.com/virtualoffice12496/form/MarketDirectcozaPurchaseOrderFundingApplication/formperma/KNHMcBROaHBa0Z8wkGlIi4nGxGybgEGFkbR7CPKs5Is?";
    const rfqUrl =
      "https://blog.marketdirect.co.za/get-tenderbriefattend-attend-tender-briefing-anywhere/?";
    window.open(
      rfqUrl +
        "utm_source=" +
        $utm_source +
        "&utm_medium=" +
        $utm_medium +
        "&utm_campaign=" +
        $utm_campaign +
        "&utm_content=" +
        $utm_content +
        "&utm_term=" +
        $utm_term +
        "&affid=" +
        affid,
      "_blank"
    );
  };

  //Handle when user click Buy Button and redirect the user to a new url
  const handleGetQuote = (event) => {
    event.preventDefault();

    //console.log("AFFILIATE ID: " + affid);

    //redirect to url but open a blank page
    //const rfqUrl ="https://forms.zoho.com/virtualoffice12496/form/MarketDirectcozaPurchaseOrderFundingApplication/formperma/KNHMcBROaHBa0Z8wkGlIi4nGxGybgEGFkbR7CPKs5Is?";
    const rfqUrl =
      "https://applications.marketdirect.co.za/virtualoffice12496/form/TolaTenderProCustomerOrderForm/formperma/rfQvQMiBim-scf3o9i-nyHcKWWf0X5gear6tvWoPins?";
    window.open(
      rfqUrl +
        "utm_source=" +
        $utm_source +
        "&utm_medium=" +
        $utm_medium +
        "&utm_campaign=" +
        $utm_campaign +
        "&utm_content=" +
        $utm_content +
        "&utm_term=" +
        $utm_term +
        "&affid=" +
        affid,
      "_blank"
    );
  };

  //Handle when user click Chat Button and redirect the user to a new url
  const handleChat = (event) => {
    event.preventDefault();

    //console.log("AFFILIATE ID: " + affid);

    //redirect to url but open a blank page
    const rfqUrl = "https://api.whatsapp.com/message/EF2LXKA73QPGJ1";
    window.open(rfqUrl, "_blank");
  };

  //Handle when user click Buy Button and redirect the user to a new url
  const getQuote = (event) => {
    event.preventDefault();

    //console.log("AFFILIATE ID: " + affid);

    //redirect to url but open a blank page
    const rfqUrl =
      "https://applications.marketdirect.co.za/virtualoffice12496/form/TenderBriefAttendCustomerOrderForm/formperma/VOrU5YLTsNj5vCvUBBtAgklI14NrxawCpBLB5wkHDsY?";

    window.open(
      rfqUrl +
        "utm_source=" +
        $utm_source +
        "&utm_medium=" +
        $utm_medium +
        "&utm_campaign=" +
        $utm_campaign +
        "&utm_content=" +
        $utm_content +
        "&utm_term=" +
        $utm_term +
        "&affid=" +
        affid,
      "_blank"
    );
  };

  return (
    //TolaTenderPro section and content
    <section id="TolaTenderPro">
      <div>
        <div className="row pt-5 align-items-right">
          <div className="col-sm p-3 order-1 align-self-start">
            <div
              id="carouselExampleSlidesOnly"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src={assets.TenderBriefAttendBoardRoomRedYellow}
                    className="d-block w-100 rounded img-fluid"
                    alt="Get TenderBriefAttend - Attend Tender Briefings Anywhere!"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={assets.TenderBriefAttendBoardRoomRed}
                    className="d-block w-100 rounded img-fluid"
                    alt="Get TenderBriefAttend - Attend Tender Briefings Anywhere!"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm p-3 order-5 align-self-start">
            <h1 className="display-4 text-white text-left fw-bolder">
              {`Get TenderBriefAttend - Attend Briefings Anywhere ${props.city}`}
            </h1>
            <p className="lead text-white text-left">
              <strong>
                Unfortunately, many small to medium-sized businesses face tender
                bid disqualification due to inability to attend tender
                briefings.
              </strong>{" "}
              <strong>TenderBriefAttend&trade;</strong> specializes in
              representing organizations at crucial tender briefings. We act as
              an extension of your team to capture vital insights, ask the right
              questions, and ensure you never miss important details that could
              affect your bid outcome.
              <br />
              <br />
              {/* <strong>
                Plus, we have something new for you! Gain immediate access to
                easier to get subcontracting opportunities from businesses that
                have already secured RFQs and tenders when you buy TolaTenderPro
                service.
              </strong>
              <br />
              <br /> */}
              <ul>
                <li>
                  <strong>Save Time & Resources:</strong> Eliminate the need to
                  send in-house teams to multiple briefings, freeing up valuable
                  capacity for other high-priority tasks.
                </li>
                <li>
                  <strong>Reduce Risk of Errors:</strong> Ensure all vital
                  details are captured accurately so you don’t miss critical
                  instructions or deadlines.
                </li>
                <li>
                  <strong>Gain Expert Insights:</strong> Leverage our experience
                  across various industries to capitalize on best practices and
                  pitfalls to avoid with our TolaTenderPro bidding service.
                </li>

                <li>
                  <strong>Stay Competitive:</strong> By having a presence at
                  every relevant tender briefing, you stay on top of new
                  opportunities and changes in the market.
                </li>
              </ul>
              <strong>
                Partner with us to bid more often to win more contracts. Book
                your FREE 30 minutes consultation, get a quote now, or learn
                more - press a blue button below!
              </strong>
            </p>
            <div className="d-flex flex-column">
              <div className="pb-10 text-center hide">
                <a className="w-50 btn btn-lg btn-primary" onClick={getQuote}>
                  GET A QUOTE AND PRICING
                </a>
              </div>
              <div className="p-10 text-center">OR</div>
              <div className="p-10 text-center">
                <a
                  className="w-50 btn btn-lg btn-primary"
                  onClick={handleRFQButton}
                >
                  BOOK A FREE CONSULTATION
                </a>
              </div>
              <div className="p-10 text-center">OR</div>
              <div className="pb-10 text-center">
                <a
                  className="w-50 btn btn-lg btn-primary"
                  onClick={handleLearnMore}
                >
                  LEARN MORE
                </a>
              </div>
              {/* <div className="p-10 text-center">OR</div>
              <div className="pb-10 text-center">
                <a className="w-50 btn btn-lg btn-primary" onClick={handleChat}>
                  CHAT WITH AN EXPERT NOW!
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TolaTenderPro;
