//create App.js file content
import React, { useEffect } from "react";
//import { SpeedInsights } from "@vercel/speed-insights/react";
//import { Analytics } from "@vercel/analytics/react";

import "./App.scss";
import {
  About,
  Header,
  Testimonial,
  Contact,
  BizzVoIP,
  ReferAndEarn,
  Terms,
  Privacy,
  Footer,
  TenderFunding,
  InvoiceCashAdvance,
  TenderFunderClub,
  TenderDeals,
  BakkieRent,
  Blog,
  TolaTenderPro,
  TenderBriefAttend,
} from "./container";

//import navbar
import {
  NavBar,
  NavBarSubVoIP,
  PricingComparisonTolaTenderPro,
  PricingComparisonTenderBriefAttend,
} from "./components";
//import helmet component
import { Helmet } from "react-helmet";
//import assets
import assets from "./constants";
//import assets from "./constants";
//import cookies
import { useCookies } from "react-cookie";
//import
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useParams } from "react-router-dom";
//import { SpeedInsights } from "@vercel/speed-insights/next";

// const { Logger, Levels } = require("zcrmsdk/routes/logger/logger");
// const UserSignature = require("zcrmsdk/routes/user_signature").UserSignature;
// const USDataCenter = require("zcrmsdk/routes/dc/us_data_center").USDataCenter;
// const {
//   OAuthToken,
//   TokenType,
// } = require("zcrmsdk/models/authenticator/oauth_token");

/* * Create an instance of Logger Class that takes two
parameters

* 1 -> Level of the log messages to be logged. Can be
configured by typing Levels "." and choose any level from
the list displayed.
* 2 -> Absolute file path, where messages need to be Zoho CRM --zoho.com/crm--
Note

The access and refresh tokens are environment-specific and domainspecific. When you handle various environments and domains such as
Production, Sandbox, or Developer and IN, CN, US, EU, or AU, respectively, you
must use the access token and refresh token generated only in those
respective environments and domains. The SDK throws an error, otherwise.

For example, if you generate the tokens for your Sandbox environment in the
CN domain, you must use only those tokens for that domain and environment.
You cannot use the tokens generated for a different environment or a domain.

For Contact Roles and Records API, you will need to provide the
ZohoCRM.settings.fields.ALL scope along with the ZohoCRM.modules.ALL
scope while generating the OAuthtoken. Otherwise, the system returns the
OAUTH-SCOPE-MISMATCH error

For Related Records API, the scopes required for generating OAuthtoken are
ZohoCRM.modules.ALL, ZohoCRM.settings.fields.ALL and
ZohoCRM.settings.related_lists.ALL. Otherwise, the system returns the
OAUTH-SCOPE-MISMATCH error
logged.*/

// let logger = Logger.getInstance(
//   Levels.INFO,
//   "/Users/user_name/Documents/nodejs_sdk_log.log"
// );
//Create an UserSignature instance that takes user Email as parameter
//let user = new UserSignature("paul@marketdirect.co.za");

/** Configure the environment
 * * which is of the pattern Domain.Environment
 * * Available Domains: USDataCenter, EUDataCenter, INDataCenter, CNDataCenter, AUDataCenter
 * * Available Environments: PRODUCTION(), DEVELOPER(), SANDBOX()
7 */
//let environment = USDataCenter.PRODUCTION();

//let logger = Logger.getInstance(Levels.ALL);
//let environment = DataCenter.US.PRODUCTION();

/*
3 * Create a Token instance
4 * 1 -> OAuth client id.
5 * 2 -> OAuth client secret.
Zoho CRM
--zoho.com/crm--
6 * 3 -> REFRESH/GRANT token.
7 * 4 -> token type.
8 * 5 -> OAuth redirect URL.
9 */

//GRANT TOKEN: 1000.b6c2d2492a81b07fc6512846571804f9.511f714a48e3d46808c60d1532c82fb0

// let token = new OAuthToken(
//   "1000.UI8P93QCQ4676486394A7Z4W5P79Y9",
//   "5d8b8cfd0d3d130682f0414a72d4545eac06b455d6",
//   "1000.b6c2d2492a81b07fc6512846571804f9.511f714a48e3d46808c60d1532c82fb0",
//   TokenType.GRANT,
//   "https://www.marketdirect.co.za/webhooks/zoho/oauth2callback"
// );

// let token = new OAuthBuilder()
//   .clientId("1000.UI8P93QCQ4676486394A7Z4W5P79Y9")
//   .scope("ZohoCRM.modules.ALL,ZohoCRM.settings.ALL")
//   .redirectURL("https://www.marketdirect.co.za/webhooks/zoho/oauth2callback")
//   .build();

//console.log("TOKEN:" + token);

// import LogRocket from "logrocket";
// LogRocket.init("uefx4y/marketdirectcoza-dev");

// // This is an example script - don't forget to change it!
// LogRocket.identify("THE_USER_ID_IN_YOUR_APP", {
//   name: "Bongani",
//   email: "manjanjap@gmail.com",
//   // Add your own custom user variables here, ie:
//   subscriptionType: "pro",
// });
//import * as Sitemap from "./container/Sitemap/Sitemap";
//Import
const ChatBox = () => {
  return (
    <>
      {/*Use the  code snippet to provide real time updates to the live chat plugin without the need of copying and paste each time to your website when changes are made via PBX*/}
      <call-us-selector
        phonesystem-url="https://thebizzphone.3cx.co.za"
        party="LiveChat514096"
      />
    </>
  );
};

// Define a function that takes a string as a parameter
function formatCity(str) {
  // Check if the string is empty or only contains whitespace
  if (!str || !str.trim()) {
    // Return nothing
    return "";
  }
  // Split the string by the dash character
  let words = str.split("-");
  // Loop through each word in the array
  for (let i = 0; i < words.length; i++) {
    // Capitalize the first letter of each word and append the rest of the word
    words[i] = words[i][0].toUpperCase() + words[i].slice(1);
  }
  // Join the words back together with a space and return the result
  return " In " + words.join(" ");
}

// Define the main page component of the single page app
const MainPage = () => {
  // Get the city name from the URL
  const { city } = useParams();
  console.log(formatCity(city));
  return (
    <>
      <NavBar />
      <Header />
      {/*<Search />
        <TenderFunding />*/}
      <BizzVoIP city={formatCity(city)} />
      <Testimonial />
      <ReferAndEarn />
      <About />
      {/*checkout
      <Checkout />*/}
      <Contact />
      <Footer />
    </>
  );
};

const POFunding = () => {
  // Get the city name from the URL
  const { city } = useParams();
  console.log(formatCity(city));
  return (
    <div className="app__tenderfunding-bg">
      <NavBar />
      {/*<Header />
        <Search />*/}
      <TenderFunding city={formatCity(city)} />
      <Testimonial />
      {/*<BizzVoIP />*/}
      <ReferAndEarn />
      {/*<About />
        checkout
      <Checkout />
        <Contact />*/}
      <Contact />
      <Footer />
    </div>
  );
};

const postData = {
  /* your JSON data here */
};

function BlogPost({ post }) {
  return (
    <div>
      <h1>{post.title}</h1>
      <p>{post.content[0].children[0].text}</p>
      {post.content.map((block, index) => {
        if (block._type === "block" && block.style === "normal") {
          return (
            <p key={index}>
              {block.children.map((span, spanIndex) => (
                <span key={spanIndex}>{span.text}</span>
              ))}
            </p>
          );
        } else if (block._type === "block" && block.listItem === "bullet") {
          return (
            <ul key={index}>
              {block.children.map((listItem, listItemIndex) => (
                <li key={listItemIndex}>{listItem.children[0].text}</li>
              ))}
            </ul>
          );
        }
      })}
    </div>
  );
}
const BlogPosts = () => {
  // Get the city name from the URL
  const { city } = useParams();
  console.log(formatCity(city));
  return (
    <div className="app__tenderfunding-bg">
      <NavBar />
      {/*<Header />
        <Search />*/}
      <Blog city={formatCity(city)} />
      {/* <Testimonial /> */}
      {/*<BizzVoIP />*/}
      {/* <ReferAndEarn /> */}
      {/*<About />
        checkout
      <Checkout />
        <Contact />*/}
      <Footer />
    </div>
  );
};
const InvoiceDiscounting = () => {
  // Get the city name from the URL
  const { city } = useParams();
  console.log(formatCity(city));
  return (
    <div className="app__tenderfunding-bg">
      <NavBar />
      {/*<Header />
        <Search />*/}
      <InvoiceCashAdvance city={formatCity(city)} />
      <Testimonial />
      {/*<BizzVoIP />*/}
      <ReferAndEarn />
      {/*<About />
        checkout
      <Checkout />
        <Contact />*/}
      <Footer />
    </div>
  );
};

const Invest = () => {
  // Get the city name from the URL
  const { city } = useParams();
  console.log(formatCity(city));
  return (
    <div className="app__tenderfunding-bg">
      <NavBar />
      {/*<Header />
        <Search />*/}
      <TenderFunderClub city={formatCity(city)} />
      <TenderDeals />
      <Testimonial />
      {/*<BizzVoIP />*/}
      <ReferAndEarn />
      {/*<About />
        checkout
      <Checkout />
        <Contact />*/}
      <Contact />
      <Footer />
    </div>
  );
};

const BakkieRental = () => {
  // Get the city name from the URL
  const { city } = useParams();
  console.log(formatCity(city));
  return (
    <div className="app__tenderfunding-bg">
      <NavBar />
      {/*<Header />
        <Search />*/}
      <BakkieRent city={formatCity(city)} />
      <Testimonial />
      {/*<BizzVoIP />*/}
      <ReferAndEarn />
      {/*<About />
        checkout
      <Checkout />
        <Contact />*/}
      <Contact />
      <Footer />
    </div>
  );
};

/* tenderpro function */
const TolaTender = () => {
  // Get the city name from the URL
  const { city } = useParams();
  console.log(formatCity(city));
  return (
    <div className="app__tenderfunding-bg">
      <NavBar />
      {/*<Header />
        <Search />*/}
      <TolaTenderPro city={formatCity(city)} />
      <PricingComparisonTolaTenderPro />
      <Testimonial />
      {/*<BizzVoIP />*/}
      <ReferAndEarn />
      {/*<About />
        checkout
      <Checkout />
        <Contact />*/}
      <Contact />
      <Footer />
    </div>
  );
};
/* tenderpro function */
const BriefAttend = () => {
  // Get the city name from the URL
  const { city } = useParams();
  console.log(formatCity(city));
  return (
    <div className="app__tenderfunding-bg">
      <NavBar />
      {/*<Header />
        <Search />*/}
      <TenderBriefAttend city={formatCity(city)} />
      <PricingComparisonTenderBriefAttend />
      {/* <PricingComparisonTolaTenderPro /> */}
      <Testimonial />
      {/*<BizzVoIP />*/}
      <ReferAndEarn />
      {/*<About />
        checkout
      <Checkout />
        <Contact />*/}
      <Contact />
      <Footer />
    </div>
  );
};
/* add zoho sales iq chat box */

const App = () => {
  // Get the city name from the URL
  const { city } = useParams();
  // const GenerateSitemap = () => {
  //   let url = Sitemap.GenerateURLs;
  //   console.log(url);
  //   // Save the URLs to a file using the saveURLs function
  //   Sitemap.SaveURLs(url);
  // };

  //extract a parameter from the url the parameter is called affid
  const params = new URLSearchParams(window.location.search);
  const paramsObject = Object.fromEntries(params); // convert it to a plain object
  //Use cookies to store a certain value from parameter called affid for 30 days from an external refferal link
  const [cookies, setCookie] = useCookies(["affid", "urlParams"]);
  //set the 30 day cookie in a side effect
  //useEffect(() => {

  if (params.has("affid") && cookies.affid === undefined) {
    // Get the "affid" value from the URL parameters
    const affid = params.get("affid");
    // Set the expiration date to 30 days from now
    const date = new Date();
    date.setDate(date.getDate() + 30);
    // Set the cookie with the name "affid" and the value from the URL parameter
    setCookie("affid", affid, {
      path: "/",
      expires: date,
    });
  }
  //}, [params, setCookie]);
  // Store all the URL parameters in a cookie for the current session
  //useEffect(() => {
  // Set the cookie with the name "urlParams" and the value as the URL parameters object
  setCookie("urlParams", paramsObject, {
    path: "/",
  });
  //}, [paramsObject, setCookie]);

  return (
    <>
      <Helmet titleTemplate="%s | MarketDirect.co.za">
        <meta charSet="utf-8" />
        <title>
          {`Tender Finance, TolaTenderPro RFQ and Tender Bidding Service and The Bizz Phone`}
        </title>
        <link rel="canonical" href="https://www.marketdirect.co.za/" />
        <link rel="icon" href={assets.favicon} />
        {/*  DESCRIPTION */}
        <meta
          name="description"
          content="Get Tender Finance, Win Tenders, and The Bizz Phone™."
        />
        {/* KEYWORDS */}
        <meta
          name="keywords"
          content="tender finance,tolatenderpro,tolatenderpro,rfq bidding,tender bidding,invoice discounting,tolatenderpro,tenderbriefattend,tender briefing,attend tender briefing,purchase order funding,b2b sourcing,3cx voip system,voip phone system,cloud hosted pbx,small business phone service,business phone systems"
        />
        {/* VIEWPORT */}
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="MobileOptimized" content="width" />
        <meta name="HandheldFriendly" content="true" />
        {/* YANDEX */}
        <meta name="yandex-verification" content="0ad5e46b6f0d8e80" />
        <script
          defer
          src="https://downloads-global.3cx.com/downloads/livechatandtalk/v1/callus.js"
          id="tcx-callus-js"
          type="text/javascript"
        ></script>
        {/* body attributes */}
        <body className="bg-danger text-white" />
      </Helmet>
      <ChatBox />
      {/*<SpeedInsights />
      <Analytics />
      <ZohoSalesIQ /> */}

      {/* <MainPage /> */}
      <BrowserRouter>
        <Routes>
          <Route index exact path="/" element={<MainPage />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route
            path="/tolatenderpro-tender-search-bid-preparation-and-submission"
            element={<TolaTender />}
          />
          <Route
            path="/tenderbriefattend-attend-tender-briefings-anywhere"
            element={<BriefAttend />}
          />
          <Route path="/invoice-discounting" element={<InvoiceDiscounting />} />
          <Route
            path="/invoice-discounting/index.php"
            element={<InvoiceDiscounting />}
          />
          <Route
            path="/l/invoice-discounting"
            element={<InvoiceDiscounting />}
          />
          <Route path="/tender-finance" element={<POFunding />} />
          <Route path="/l/tender-finance" element={<POFunding />} />
          <Route path="/l/tender-finance/index.php" element={<POFunding />} />
          <Route
            path="/l/voip-phone-system-and-cloud-hosted-pbx"
            element={<MainPage />}
          />
          <Route
            path="/l/voip-phone-system-and-cloud-hosted-pbx"
            element={<MainPage />}
          />
          <Route path="/3cx-voip-phone/:city" element={<MainPage />} />
          <Route path="/3cx-voip-phone-in-:city" element={<MainPage />} />
          <Route path="/tender-finance/:city" element={<POFunding />} />
          <Route path="/tender-finance-in-:city" element={<POFunding />} />
          <Route path="/tender-funding/:city" element={<POFunding />} />
          <Route path="/tender-funding-in-:city" element={<POFunding />} />
          <Route path="/purchase-order-finance/:city" element={<POFunding />} />
          <Route path="/purchase-order-finance" element={<POFunding />} />
          <Route
            path="/purchase-order-finance-in-:city"
            element={<POFunding />}
          />
          <Route path="/purchase-order-funding/:city" element={<POFunding />} />
          <Route path="/purchase-order-funding" element={<POFunding />} />
          <Route
            path="/purchase-order-funding-in-:city"
            element={<POFunding />}
          />
          <Route
            path="/invoice-discounting/:city"
            element={<InvoiceDiscounting />}
          />
          {/* Keyword: construction-tender-finance */}
          <Route
            path="/construction-tender-finance/:city"
            element={<POFunding />}
          />
          <Route path="/construction-tender-finance" element={<POFunding />} />
          {/* Keyword: construction-tender-funding */}
          <Route
            path="/construction-tender-funding/:city"
            element={<POFunding />}
          />
          <Route path="/construction-tender-funding" element={<POFunding />} />
          <Route path="/construction-finance/:city" element={<POFunding />} />
          <Route path="/construction-finance" element={<POFunding />} />
          <Route path="/construction-funding/:city" element={<POFunding />} />
          <Route path="/construction-funding" element={<POFunding />} />
          <Route path="/3cx-voip-phone/:city" element={<MainPage />} />
          <Route path="/3cx-voip-phone" element={<MainPage />} />
          <Route path="/voip/:city" element={<MainPage />} />
          <Route path="/voip" element={<MainPage />} />
          <Route path="/TheBizzPhone" element={<MainPage />} />
          <Route path="/3cx-voip-phone-in-:city" element={<MainPage />} />
          <Route path="/invest/:city" element={<Invest />} />
          <Route path="/invest" element={<Invest />} />
          {/* Keyword: market-direct-tender-funder-club-crowdfunding-platform */}
          <Route
            path="/market-direct-tender-funder-club-crowdfunding-platform/:city"
            element={<Invest />}
          />
          <Route
            path="/market-direct-tender-funder-club-crowdfunding-platform"
            element={<Invest />}
          />
          <Route path="/rfq" element={<Invest />} />
          <Route path="/rfq/:city" element={<Invest />} />
          <Route path="/request-for-quotation" element={<Invest />} />
          <Route path="/request-for-quotation/:city" element={<Invest />} />
          {/* Keyword: tender-crowd-funding */}
          <Route path="/tender-crowd-funding/:city" element={<Invest />} />
          <Route path="/tender-crowd-funding" element={<Invest />} />
          {/* Keyword: funding-for-women-in-construction */}
          <Route
            path="/funding-for-women-in-construction/:city"
            element={<POFunding />}
          />
          <Route
            path="/funding-for-women-in-construction"
            element={<POFunding />}
          />
          {/* Keyword: tender-crowd-funding */}
          <Route path="/tender-crowd-funding/:city" element={<Invest />} />
          <Route path="/tender-crowd-funding" element={<Invest />} />
          {/* Bakkie Rental */}
          <Route path="/bakkie-rental/:city" element={<BakkieRental />} />
          <Route path="/bakkie-rental" element={<BakkieRental />} />
          <Route path="/bakkie-rental-in-:city" element={<BakkieRental />} />
          {/* Bakkie Rental: Legacy */}
          <Route path="/l/bakkie-rental/:city" element={<BakkieRental />} />
          <Route path="/l/bakkie-rental" element={<BakkieRental />} />
          <Route path="/l/bakkie-rental-in-:city" element={<BakkieRental />} />
          {/* Bakkie Hire: Legacy  */}
          <Route path="/l/bakkie-hire/:city" element={<BakkieRental />} />
          <Route path="/l/bakkie-hire-in-:city" element={<BakkieRental />} />
          <Route path="/l/bakkie-hire" element={<BakkieRental />} />
          {/* Bakkie Hire*/}
          <Route path="/bakkie-hire/:city" element={<BakkieRental />} />
          <Route path="/bakkie-hire-in-:city" element={<BakkieRental />} />
          <Route path="/bakkie-hire" element={<BakkieRental />} />
          {/* Keyword: bakkie-furniture-removal*/}
          <Route
            path="/l/bakkie-furniture-removal"
            element={<BakkieRental />}
          />
          <Route
            path="/l/bakkie-furniture-removal/:city"
            element={<BakkieRental />}
          />
          <Route
            path="/l/bakkie-furniture-removal-in-:city"
            element={<BakkieRental />}
          />
          <Route path="/l/furniture-removal" element={<BakkieRental />} />
          <Route path="/l/furniture-removal/:city" element={<BakkieRental />} />
          <Route
            path="/l/furniture-removal-in-:city"
            element={<BakkieRental />}
          />
          {/* Blog*/}
          {/* <Route path="/blog/:city" element={<BlogPosts />} />
          <Route path="/blog-in-:city" element={<BlogPosts />} />
          <Route path="/blog" element={<Blog />} /> */}
          {/* <Route path="/sitemap.xml" element={<Sitemap.RenderSitemap />} />
          <Route path="/generate-sitemap" element={<GenerateSitemap />} /> */}
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
